// Here you can add other styles
a {
    text-decoration: none;
    background-color: transparent;
    color: #1f759e;
}
.badge-primary {
    color: #fff;
    background-color: #0a78a6;
}
.bg-primary {
    background-color: #0a5177 !important;
}
.btn-primary {
    color: #fff;
    background-color: #0a5177;
    border-color: #0a5177;
}
.btn-link {
    font-weight: 400;
    color: #0a5177;
    text-decoration: none;
}
// .text-center.my-5 {
//     h2{  opacity:0;}
// }
.langChange{
    position: relative;
    cursor: pointer;
}